import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';

import { DataType } from './types';

export const ExportDataType: React.FC<{
  dataType: DataType;
  setDataType: (type: DataType) => void;
}> = ({ dataType, setDataType }) => {
  return (
    <Layout.Column>
      <OpenSans.Secondary size="xs-12" color={'primary'} weight="bold-700">
        {getTranslation('Data')}
      </OpenSans.Secondary>
      <Spacer.Vertical size={12} />
      <Layout.Row align>
        <Layout.PressableRow
          onClick={() => setDataType(DataType.transactions)}
          bg="inputBackground"
          border={
            dataType === DataType.transactions
              ? [1, 'solid', 'primary']
              : [1, 'solid', 'inputBorder']
          }
          py={4}
          px
          radius={5}
          align
        >
          <Icon name="cash-outline" size={16} color={theme.colors.primary} />
          <Spacer.Horizontal size={8} />
          <OpenSans.Primary>{getTranslation('Transactions')}</OpenSans.Primary>
        </Layout.PressableRow>
        <Spacer.Horizontal size={32} />
        <Layout.PressableRow
          onClick={() => setDataType(DataType.trips)}
          bg="inputBackground"
          border={
            dataType === DataType.trips ? [1, 'solid', 'primary'] : [1, 'solid', 'inputBorder']
          }
          py={4}
          px
          radius={5}
          align
        >
          <Icon name="car-outline" size={16} color={theme.colors.primary} />
          <Spacer.Horizontal size={8} />
          <OpenSans.Primary>{getTranslation('Trips')}</OpenSans.Primary>
        </Layout.PressableRow>
      </Layout.Row>

      <Spacer.Vertical size={16} />
    </Layout.Column>
  );
};
