import Bugsnag from '@bugsnag/js';
import {
  getAcceptInviteFn,
  getSendInviteVerifyEmailFn,
  getVerifyEmailFn,
} from '@easy-expense/auth-client';
import { fuego, reloadUserToken } from '@easy-expense/firebase-app';
import { Icon } from '@easy-expense/ui-shared-components';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { DoNotFixMeIAmAny } from '@easy-expense/utils-typescript';
import React from 'react';
import { Linking, Image } from 'react-native';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from '../components/Button.components';
import { SignUpForm } from '../components/SignUp.form';
import { useWindowDimensions } from '../hooks/useWindow';

const SniperLink: React.FC<React.PropsWithChildren<{ email: string }>> = ({ email }) => {
  let link = `https://mail.google.com/mail/u/${email}/#search/from%3A%40easy-expense.com+in%3Aanywhere+newer_than%3A1h`;
  let inboxName = 'Gmail';
  let imageSrc = 'gmail';
  const [, domain] = email.split('@');
  switch (domain) {
    case 'yahoo.com':
      inboxName = 'Yahoo Mail';
      imageSrc = 'yahoo';
      link = `https://mail.yahoo.com/d/search/keyword=from%253Aeasy-expense.com`;
      break;
    case 'hotmail.com':
    case 'outlook.com':
    case 'live.com':
      inboxName = 'Outlook';
      imageSrc = 'outlook';
      link = `https://outlook.live.com/mail/?login_hint=${encodeURIComponent(email)}`;
      break;
    default:
      break;
  }
  return (
    <Button.White
      // @ts-ignore
      style={{ width: 250 }}
      onClick={() => {
        Linking.openURL(link);
      }}
      py
    >
      <Layout.Row center align>
        <Image
          source={require(`../../assets/logos/${imageSrc}.png`)}
          style={{ width: 24, height: 24 }}
        />
        <Spacer.Horizontal />
        <OpenSans.Primary weight="bold-700">Open {inboxName}</OpenSans.Primary>
        <Spacer.Horizontal />
        <Icon name="open-outline" size={24} />
      </Layout.Row>
    </Button.White>
  );
};

export const InviteAccept: React.FC = () => {
  const { width } = useWindowDimensions();
  const small = width < 800;
  const baseImageSize = small ? 60 : 120;
  const [formState, setState] = React.useState<'none' | 'working'>('none');
  const [errorState, setErrorState] = React.useState<string | null>(null);
  const [loginValue, setLoginValue] = React.useState(false);
  const [imageStyle, setImageStyle] = React.useState<object | null>(null);
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const currentUser = fuego.auth().currentUser;

  const workspaceName = params.get('workspaceName') ?? '';
  const workspaceLogo = params.get('workspaceLogo') ?? '';
  const inviteID = params.get('inviteID') ?? '';
  const userID = currentUser?.uid ?? '';

  const processInvite = (accept: boolean) => {
    setState('working');
    getAcceptInviteFn()({
      inviteID: params.get('inviteID') as string,
      accept,
    })
      .then(({ data }) => {
        if (data?.success) {
          if (accept) {
            navigate?.('accepted');
          } else {
            navigate('/transactions');
          }
        } else {
          setErrorState(data.errorMessage ?? 'An error occurred...');
        }
      })
      .catch((e) => {
        setState('none');
        setErrorState('An error occurred...');
        Bugsnag.notify(e);
      });
  };

  const checkUserVerification = (verifyToken: string) => {
    setState('working');
    getVerifyEmailFn()({ verifyToken })
      .then(async ({ data }) => {
        if (data.success) {
          await reloadUserToken();
          if (currentUser?.emailVerified) {
            processInvite(true);
          }
        }
      })
      .finally(() => {
        setState('none');
      });
  };

  React.useEffect(() => {
    if (currentUser) {
      if (currentUser.emailVerified) {
        processInvite(true);
      } else if (params.get('verifyToken')) {
        checkUserVerification(params.get('verifyToken') as string);
      } else {
        getSendInviteVerifyEmailFn()({
          workspaceName,
          workspaceLogo,
          inviteID,
          userID,
        });
      }
    }
  }, [currentUser]);

  React.useEffect(() => {
    if (!params.get('inviteID')) {
      navigate?.('/');
    }
  }, [params, navigate]);

  React.useEffect(() => {
    if (workspaceLogo) {
      Image.getSize(workspaceLogo, (width, height) => {
        // TODO @wmatous handle wide images
        const ratio = width / height;
        setImageStyle(
          ratio < 1
            ? { width: baseImageSize, height: baseImageSize / ratio }
            : { width: baseImageSize * ratio, height: baseImageSize },
        );
      });
    }
  }, [params]);

  function renderInviteAction() {
    if (errorState) {
      return (
        <Layout.Column center>
          <OpenSans.Primary size="l-20" center>
            {errorState}
          </OpenSans.Primary>
          <Spacer.Vertical size={small ? 12 : 18} />
          <OpenSans.Secondary size="l-20" center>
            <a href="mailto:support@easy-expense.com">Contact Support</a>
          </OpenSans.Secondary>
        </Layout.Column>
      );
    }
    if (formState === 'working' || currentUser?.emailVerified) {
      return (
        <Layout.Column center>
          <OpenSans.Primary size="l-20" center>
            Working...
          </OpenSans.Primary>
        </Layout.Column>
      );
    }
    if (currentUser) {
      return (
        <OpenSans.Primary weight="regular-400" size={small ? 'l-20' : '2xl-32'}>
          <Layout.Column center>
            <Spacer.Vertical size={small ? 12 : 18} />
            <OpenSans.Primary size="l-20" center>
              Verify your email to continue ({currentUser.email})
            </OpenSans.Primary>
            <Spacer.Vertical size={small ? 12 : 18} />
            <SniperLink email={currentUser.email!} />
            <Spacer.Vertical size={small ? 12 : 18} />
            <Button.Default
              // @ts-ignore
              style={{ width: 250 }}
              onClick={async () => {
                try {
                  await getSendInviteVerifyEmailFn()({
                    workspaceName,
                    workspaceLogo,
                    inviteID,
                    userID,
                  });
                  alert('Please check your inbox and spam folder for verification instructions');
                } catch (e) {
                  Bugsnag.notify(e as DoNotFixMeIAmAny);
                  alert('An error occurred, please try again');
                }
              }}
              py
            >
              <OpenSans.Primary weight="bold-700">Resend Email</OpenSans.Primary>
            </Button.Default>
            {/* <Spacer.Vertical size={small ? 12 : 18} />
              <Button.Default
                // @ts-ignore
                style={{ width: 250 }}
                onClick={async () => {
                  await reloadUserToken();
                  if (currentUser?.emailVerified) {
                    processInvite(true);
                  } else {
                    alert('Email not yet verified');
                  }
                }}
                py
              >
                <OpenSans.Primary weight="bold-700">Refresh</OpenSans.Primary>
              </Button.Default> */}
          </Layout.Column>
        </OpenSans.Primary>
      );
    }
    return (
      <>
        <OpenSans.Primary weight="regular-400" size={small ? 'l-20' : '2xl-32'}>
          <p>
            {loginValue ? 'Log In' : 'Sign up'} to join the
            <b>{workspaceName ? ` ${workspaceName} ` : ''}</b>
            workspace
          </p>
        </OpenSans.Primary>
        <Spacer.Vertical size={small ? 24 : 36} />
        <SignUpForm onSuccess={async () => {}} />
      </>
    );
  }

  return (
    <Layout.Column style={{ height: '100vh' }} py px>
      <Layout.Row py>
        <Layout.Column grow>
          <Image
            source={require('../images/icons/logo.png')}
            style={{ height: baseImageSize, width: small ? 161 : 322 }}
          />
        </Layout.Column>
        {workspaceLogo ? (
          <Layout.Column>
            <Image source={{ uri: workspaceLogo }} style={imageStyle} />
          </Layout.Column>
        ) : null}
      </Layout.Row>
      <Layout.Column align>{renderInviteAction()}</Layout.Column>
    </Layout.Column>
  );
};
