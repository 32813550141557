import { getReportPreviewHtmlFn } from '@easy-expense/auth-client';
import {
  useCachedReportWithData,
  upsertClient,
  useTopClients,
  useCachedClients,
  useCurrentWorkspace,
  firestoreWorkspaceCollectionRef,
} from '@easy-expense/data-firestore-client';
import { entityFields } from '@easy-expense/data-firestore-shared';
import {
  ReportWithData,
  ReportWithDataSchema,
  ReportStatus,
  ReportSchema,
} from '@easy-expense/data-schema-v2';
import { getTranslation, useIntlStore } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer, zIndex } from '@easy-expense/ui-web-core';
import cuid from 'cuid';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';

import { DownloadReportCSVButton } from './DownloadReportCSVButton';
import { DownloadReportPDFButton } from './DownloadReportPDFButton';
import { ReportAddTransactions } from './ReportAddTransactions.component';
import { ReportName } from './ReportName.component';
import { ReportStatusSelector } from './ReportStatus.component';
import { ShareReport } from './ShareReportButton';
import { TripCard } from './TripCard';
import { auth } from '../../firebase/app';
import { Button } from '../Button.components';
import { ExpenseList } from '../Expense/ExpenseList';
import LoadingSpinner from '../LoadingSpinner.component';
import { CloseButton } from '../Shared/CloseButton.component';
import { InsertDropdown } from '../Shared/Dropdown/InsertableDropdown.component';

export const ReportDetail: React.FC<React.PropsWithChildren<{ reportKey?: string }>> = ({
  reportKey,
}) => {
  const navigate = useNavigate();
  const cachedReport = useCachedReportWithData(reportKey);
  const workspace = useCurrentWorkspace();
  const { formatCurrency } = useIntlStore();
  const clients = useCachedClients();
  const [clientName, setClientName] = React.useState<string | null>(null);
  const [report, setReport] = React.useState<ReportWithData | null>(cachedReport);
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const [previewLoading, setPreviewLoading] = React.useState<boolean>(false);
  const [isNewReport, setIsNewReport] = React.useState<boolean>(false);
  const sharedWith = React.useRef<string | undefined>(undefined);

  const [user] = useAuthState(auth);
  const [saveURL, setSaveURL] = React.useState<string | null>(null);

  //done so that the fetchReportSummary is not called when status is updated since it does not appear on the pdf
  const [newStatus, setNewStatus] = React.useState<ReportStatus>(cachedReport?.status ?? 'Unsent');
  const [viewAddTransactions, setViewAddTransactions] = React.useState<boolean>();

  const previewHtmlRef = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    if (!cachedReport && user) {
      const newReportWithData = ReportWithDataSchema.safeParse({
        ...entityFields('create', user?.uid),
        name: 'New Report',
        status: 'Unsent',
        expenses: [],
        incomes: [],
        trips: [],
        key: cuid(),
        tripTotal: 0,
        expenseTotal: 0,
        incomeTotal: 0,
        total: 0,
        reportExpenses: [],
        reportIncomes: [],
        reportTrips: [],
        canEdit: true,
      });

      if (newReportWithData.success) {
        setIsNewReport(true);
        setReport(newReportWithData.data);
        reloadReportPreview(newReportWithData.data);

        return;
      }

      throw `error creating a new report with data ${JSON.stringify(newReportWithData, null, 2)}`; // open the error page
    }
  }, [cachedReport, user]);

  React.useEffect(() => {
    if (report && !viewAddTransactions) {
      reloadReportPreview(report);
    }
  }, [viewAddTransactions]);

  const screenWidth = window.innerWidth > 0 ? window.innerWidth : screen.width;

  async function onSaveReport() {
    try {
      const clientKey = clientName
        ? upsertClient({
            name: clientName,
          })
        : undefined;

      /**
       * This is a hack to get around being able to set the createBy field when updating entities
       * This will be fixed in https://linear.app/easy-expense/issue/EE-3149/extend-the-report-data-type-to-include-shared-userid
       */

      const entityfields = isNewReport
        ? { ...entityFields('create', user?.uid ?? '') }
        : { ...entityFields('update', user?.uid ?? '') };

      const key = report?.key ?? cuid();
      const parsedReport = ReportSchema.partial().parse({
        ...report,
        ...entityfields,
        createdBy: sharedWith.current ?? report?.createdBy ?? user?.uid ?? '',
        key,
        status: newStatus,
      });
      const workspaceCollectionRef = firestoreWorkspaceCollectionRef('report');
      workspaceCollectionRef.doc(key).set(parsedReport);

      /* hack end */

      navigate(`/reports`);
    } catch (err) {
      alert('There was an error saving the report');
    }
  }

  async function reloadReportPreview(reportToPreview: ReportWithData) {
    setPreviewLoading(true);
    const reportHtml = await getReportPreviewHtmlFn()({
      report: reportToPreview,
      orgID: workspace.org,
      workspaceID: workspace.key,
    });

    setPreviewLoading(false);

    if (!reportHtml) {
      return;
    }

    if (previewHtmlRef.current) {
      const shadowRoot = previewHtmlRef.current.attachShadow({ mode: 'open' });
      shadowRoot.innerHTML = reportHtml.data.reportHTML;
    }
  }

  function closeReportDetail(navPath: string = '/reports') {
    if (isDirty) {
      if (
        confirm(
          getTranslation('There are unsaved changes on this report, do you wish to discard it?'),
        )
      ) {
        navigate(navPath);
      }
    } else {
      navigate(navPath);
    }
  }

  function setReportChanged(isChanged: boolean) {
    setIsDirty(isChanged);
  }

  if (!report) {
    return null;
  }

  if (viewAddTransactions) {
    return (
      <>
        <ReportAddTransactions
          onClose={() => setViewAddTransactions(false)}
          report={report}
          setReportDirty={() => setReportChanged(true)}
        />
      </>
    );
  }

  return (
    <Layout.Column style={{ width: '100%', height: '100vh' }}>
      <Layout.Row>
        {screenWidth > 1000 ? (
          <>
            {previewLoading ? (
              <Layout.Column
                style={{
                  width: '100%',
                  maxWidth: 600,
                  height: '100%',
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  justifyContent: 'center',
                  position: 'absolute',
                  zIndex: zIndex.LoadingOverlay,
                }}
              >
                <LoadingSpinner />
              </Layout.Column>
            ) : null}
            <Layout.Column
              style={{
                maxWidth: 600,
                backgroundColor: '#CFD6E0',
              }}
              height={'100%'}
              px={10}
              py={100}
              grow
              className="hide-on-mobile"
            >
              <Layout.Column style={{ backgroundColor: '#fff' }}>
                <div ref={previewHtmlRef}></div>
              </Layout.Column>
            </Layout.Column>
          </>
        ) : null}

        <Layout.Column py={24} px={32} grow style={{ height: '100vh', overflow: 'scroll' }}>
          <Layout.Row>
            <Spacer.Flex />
            <Layout.Column style={{ minWidth: 650, maxWidth: 1200, width: '100%' }}>
              <Layout.Row py style={{ width: '70%' }}>
                <ReportName
                  name={report.name}
                  onChange={(name) => {
                    setReport({ ...report, name });
                    setReportChanged(true);
                  }}
                />
              </Layout.Row>

              <Layout.Row py style={{ width: '60%' }}>
                <InsertDropdown
                  header={getTranslation('Client')}
                  placeholder={getTranslation('Client Name')}
                  selectedKey={report.client}
                  autoCompleteValues={clients}
                  onSelect={(clientName) => {
                    setReportChanged(true);
                    if (clientName?.key) {
                      setReport((prev) =>
                        ReportWithDataSchema.parse({ ...prev, client: clientName.key }),
                      );

                      setClientName(clientName.value.name);
                    } else if (clientName?.value.name) {
                      setClientName(clientName.value.name);
                    }
                  }}
                  allowCreateNew
                  iconicIcon="person-circle-outline"
                  error={false}
                />

                <Spacer.Horizontal size={16} />
                <ReportStatusSelector
                  status={newStatus}
                  onChange={(status) => {
                    setNewStatus(status);
                    setReportChanged(true);
                  }}
                />
              </Layout.Row>
              <Spacer.Vertical />
              <Layout.Row>
                <ShareReport
                  createdBy={report.createdBy}
                  onSelected={(r) => {
                    sharedWith.current = r?.key;
                  }}
                />
              </Layout.Row>

              <Spacer.Vertical size={16} />
              <Layout.Row py>
                <OpenSans.Primary size={32}>{getTranslation('Transactions')}</OpenSans.Primary>
                <Spacer.Horizontal size={32} />

                <Layout.PressableRow
                  onClick={() => setViewAddTransactions(true)}
                  bg="inputBackground"
                  border={[1, 'solid', 'inputBorder']}
                  px
                  radius={100}
                  align
                >
                  <Icon name="add-outline" size={16} color={theme.colors.primary} />
                  <Spacer.Horizontal size={8} />
                  <OpenSans.Primary>{getTranslation('Add/Remove Transactions')}</OpenSans.Primary>
                </Layout.PressableRow>
              </Layout.Row>

              <Layout.Row py justify="space-between" style={{ width: '100%' }}>
                <Layout.Row px border={[1, 'solid', 'primary']} radius={8} align bg="white">
                  <Icon name="trending-down-outline" size={42} color={theme.colors.primary} />
                  <Spacer.Horizontal size={12} />
                  <Layout.Column>
                    <OpenSans.Primary weight="bold-700" size="s-16">
                      {formatCurrency(Math.abs(report?.total ?? 0))}
                    </OpenSans.Primary>
                    <OpenSans.Primary size="xs-12">{getTranslation('Expenses')}</OpenSans.Primary>
                  </Layout.Column>
                </Layout.Row>

                {!isNewReport ? (
                  <Layout.Row>
                    <DownloadReportPDFButton
                      reportID={report.key}
                      saveURL={saveURL}
                      setSaveURL={setSaveURL}
                    />

                    <Spacer.Horizontal size={16} />

                    <DownloadReportCSVButton reportID={report.key} />
                  </Layout.Row>
                ) : null}
              </Layout.Row>

              <Spacer.Vertical size={16} />

              {report?.expenses && report.expenses.length > 0 && (
                <Layout.Column style={{ width: '100%' }}>
                  <ExpenseList
                    filter={(e) => (report.expenses ?? []).includes(e.key)}
                    showBulk={false}
                    selected={report.expenses}
                    setSelected={() => {}}
                    isReport={true}
                    onRowClick={(expense) => {
                      closeReportDetail(`/expense/${expense.key}`);
                    }}
                    showSearch={false}
                  />
                  <Spacer.Vertical />
                </Layout.Column>
              )}

              {report?.trips && report.trips.length > 0 && (
                <>
                  <TripCard report={report as ReportWithData} />
                  <Spacer.Vertical />
                </>
              )}

              <Spacer.Vertical size={64} />

              <Layout.Row
                py
                style={{
                  justifyContent: 'start',
                  position: 'fixed',
                  bottom: '20px',
                  zIndex: zIndex.SaveButton,
                  minWidth: 600,
                }}
              >
                <Button.Primary
                  onClick={onSaveReport}
                  radius={50}
                  style={{
                    border: `2px solid ${theme.colors.white}`,
                  }}
                >
                  <OpenSans.Custom
                    size={15}
                    weight="bold-700"
                    style={{ color: theme.colors.buttonWhite }}
                  >
                    Save Report
                  </OpenSans.Custom>
                  <Icon
                    size={15}
                    color={theme.colors.buttonWhite}
                    style={{ paddingLeft: 10 }}
                    name="chevron-forward"
                  />
                </Button.Primary>
              </Layout.Row>
            </Layout.Column>
            <Spacer.Flex />
          </Layout.Row>
        </Layout.Column>
      </Layout.Row>
      <CloseButton onClose={() => closeReportDetail()} />
    </Layout.Column>
  );
};
