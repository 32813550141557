import {
  useCachedMember,
  useCachedReports,
  useCachedWorkspaceMembership,
} from '@easy-expense/data-firestore-client';
import {
  Expense,
  CategoryValue,
  VendorValue,
  PaymentMethodValue,
  Report,
} from '@easy-expense/data-schema-v2';
import { getTranslation, useIntlStore } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { dateFormatSkeleton, getUserDisplayName, hueToColor } from '@easy-expense/utils-shared';
import React from 'react';
import { Image } from 'react-native';

import { CategoryBadge } from './CategoryBadge.component';
import { ReceiptButton } from './ReceiptButton.component';
import { Circle } from '../Shape.components';
import { Badge } from '../Shared/Badge.component';
import { BulkCheckbox } from '../Shared/BulkCheckbox.component';

export type ExpenseWithData = Expense & {
  categoryValue?: CategoryValue;
  vendorValue?: VendorValue;
  paymentMethodValue?: PaymentMethodValue;
  creatorDisplayName: string;
  reports: Report[];
};

export const Vendor: React.FC<React.PropsWithChildren<{ vendor?: VendorValue }>> = ({ vendor }) => {
  if (!vendor) {
    return null;
  }

  return (
    <Layout.Row style={{ maxWidth: 250 }} align>
      {vendor.image ? (
        <Image
          source={{ uri: vendor.image }}
          style={{
            height: 24,
            width: 24,
            borderRadius: 100,
            borderWidth: 1,
            borderColor: theme.colors.grayLight,
          }}
        />
      ) : (
        <Circle.Solid bg="brandPrimaryLight" circleSize={24} center>
          <OpenSans.Primary size={12}>{vendor.name[0]}</OpenSans.Primary>
        </Circle.Solid>
      )}
      <Spacer.Horizontal size={8} />
      <Layout.Row grow style={{ overflow: 'hidden' }}>
        <OpenSans.Primary size="s-12" singleLine={{ width: '100%' }}>
          {vendor.name}
        </OpenSans.Primary>
      </Layout.Row>
    </Layout.Row>
  );
};

//TODO MOVE THIS TO FILE EE-2747 https://linear.app/easy-expense/issue/EE-2747/move-report-table-component-to-a-file
export const Reports: React.FC<React.PropsWithChildren<{ reports?: Report[] }>> = ({ reports }) => {
  const report = reports?.[0];

  if (!report) {
    return (
      <Layout.Row
        style={{ backgroundColor: theme.colors.inputBackground }}
        px={8}
        py={4}
        radius={4}
        align
        border={[1, 'solid', 'inputBorder']}
      >
        <Icon name="add" size={12} color={theme.colors.primary} />
        <Spacer.Horizontal size={6} />
        <OpenSans.Primary size="s-12" style={{ overflow: 'hidden' }} grow>
          {getTranslation('Add to Report')}
        </OpenSans.Primary>
      </Layout.Row>
    );
  }

  if (reports.length > 1) {
    return (
      <Layout.Row style={{ backgroundColor: theme.colors.grayXXLight }} px={8} py={4} radius={4}>
        <OpenSans.Primary size="s-12" style={{ overflow: 'hidden' }} grow>
          {getTranslation('Multiple Reports')}
        </OpenSans.Primary>
      </Layout.Row>
    );
  }

  return <Badge color={hueToColor(report.color)} text={report.name} />;
};

export const ExpenseRow: React.FC<{
  expense: ExpenseWithData;
  onRowClick: (expense: Expense) => void;
  selected?: boolean;
  onSelect?: (state: boolean) => void;
  showBulk?: boolean;
  isReport?: boolean;
  report?: Report;
}> = ({ expense, onRowClick, selected, onSelect, showBulk, isReport = false, report }) => {
  const { formatDate, formatCurrency } = useIntlStore();
  const member = useCachedMember(expense.createdBy);
  const membership = useCachedWorkspaceMembership(expense.createdBy);
  let reports = useCachedReports(expense.key);
  if (report && report.expenses.find((re) => re === expense.key)) {
    reports = [...reports, report];
  }

  const [dollars, cents] = formatCurrency(expense.total).split('.');

  return (
    <Layout.TableRow align onClick={() => onRowClick(expense)}>
      {showBulk ? (
        <Layout.TableCell>
          <BulkCheckbox selected={selected} onSelect={() => onRowClick(expense)} />
        </Layout.TableCell>
      ) : null}

      <Layout.TableCell>
        <Layout.Column style={{ width: 60 }}>
          <OpenSans.Secondary size={'s-16'}>
            {formatDate(new Date(expense.date), {
              skeleton: dateFormatSkeleton.abbreviatedNoYear,
            })}
          </OpenSans.Secondary>
        </Layout.Column>
      </Layout.TableCell>

      <Layout.TableCell>
        <Vendor vendor={expense.vendorValue} />
      </Layout.TableCell>

      <Layout.TableCell py>
        <CategoryBadge category={expense.categoryValue} />
      </Layout.TableCell>

      {!isReport ? (
        <>
          <Layout.TableCell>
            <OpenSans.Primary size="s-16" style={{ width: 100 }}>
              {getUserDisplayName(member)}
            </OpenSans.Primary>
          </Layout.TableCell>

          <Layout.TableCell>
            <OpenSans.Primary
              size="s-16"
              style={{ width: 150 }}
              color={expense.paymentMethodValue ? undefined : 'secondary'}
            >
              {expense.paymentMethodValue ? expense.paymentMethodValue.name : 'None'}
            </OpenSans.Primary>
          </Layout.TableCell>

          <Layout.TableCell>
            <Layout.Row>
              <Reports reports={reports} />
            </Layout.Row>
          </Layout.TableCell>
        </>
      ) : null}

      <Layout.TableCell>
        <Layout.Column align="flex-end">
          <OpenSans.Primary style={{ whiteSpace: 'nowrap' }} size="s-16">
            -{dollars}
            <OpenSans.Primary size="xs-12" py={1}>
              .{cents}
            </OpenSans.Primary>
          </OpenSans.Primary>
        </Layout.Column>
      </Layout.TableCell>

      <Layout.TableCell>
        <Layout.Column align="center">
          <ReceiptButton receipt={expense.receipts[0]} setModalOpen={() => {}} />
        </Layout.Column>
      </Layout.TableCell>
    </Layout.TableRow>
  );
};
