import { currentUser } from '@easy-expense/auth-client';
import {
  useFirestoreWorkspaceDocumentPath,
  DownloadExpensesRequestDatabaseOps,
  FilteredPDFRequestCRUD,
} from '@easy-expense/data-firestore-client';
import { entityFields } from '@easy-expense/data-firestore-shared';
import {
  DownloadExpenseRequestSchema,
  SummaryUnit,
  DownloadRequestFilterSchema,
  SharedFile,
  ExportTypeValue,
  DownloadRequestFilter,
  FilteredPDFRequestFilter,
  EmailOptions,
  FilteredPDFRequestSchema,
  FilteredPDFRequestFilterSchema,
  ExportFileTypeValue,
} from '@easy-expense/data-schema-v2';
import { useDocumentData } from '@easy-expense/firebase-hooks';
import cuid from 'cuid';
import React from 'react';

import { DataExport, DataType, DownloadType } from '../../components/Export/types';

export type ContentType =
  | 'Report-pdf'
  | 'Summary-pdf'
  | 'Report-csv'
  | 'Expenses-csv'
  | 'Income-csv'
  | 'Trips-csv';

export type ExportFilterValues = DownloadRequestFilter &
  FilteredPDFRequestFilter & {
    allDates: boolean;
    contentType: ContentType;
    dateStart: Date;
    dateEnd: Date;
    recipientEmail: string;
    emailOptions: EmailOptions | null;
    headerTitle: string;
    summaryUnit: string;
    requestKey: string;
  };

export function useDownloadReport(reportID?: string) {
  const user = currentUser();
  const key = React.useRef(cuid());
  const [usedDoc, { error }] = useDocumentData(
    useFirestoreWorkspaceDocumentPath('downloadExpensesRequest', key.current),
  );
  const [addRequest] = DownloadExpensesRequestDatabaseOps.useAdd();

  const [sharedResource] = useDocumentData<SharedFile>(
    usedDoc?.sharedResourceId ? `sharedResources/${usedDoc.sharedResourceId}` : null,
  );

  async function submitDownloadRequest() {
    const filter = DownloadRequestFilterSchema.parse({
      dateStart: null,
      dateEnd: null,
      summaryUnit: SummaryUnit.Values.year,
      includeArchived: false,
      includeAttachments: false,
      splitUser: false,
      reportID: reportID ?? '',
    });

    const downloadExpenseRequest = DownloadExpenseRequestSchema.parse({
      filter,
      key: key.current,
      exportType: 'Report',
      ...entityFields('create', user?.uid ?? 'none'),
      exportFileType: 'csv',
    });

    await addRequest(downloadExpenseRequest);
  }

  return { submitDownloadRequest, error, sharedResource };
}

export function useExportData(dataExport: DataExport) {
  const user = currentUser();
  const key = React.useRef(cuid());
  const [usedDoc, { error }] = useDocumentData(
    useFirestoreWorkspaceDocumentPath('downloadExpensesRequest', key.current),
  );
  const [addRequest] = DownloadExpensesRequestDatabaseOps.useAdd();

  const [sharedResource] = useDocumentData<SharedFile>(
    usedDoc?.sharedResourceId ? `sharedResources/${usedDoc.sharedResourceId}` : null,
  );

  function getExportType(): ExportTypeValue {
    if (dataExport.downloadType === DownloadType.pdf) {
      return 'Report';
    }
    if (dataExport.dataType === DataType.trips) {
      return 'Trips';
    }
    return 'Expenses';
  }

  function getExportFileType(): ExportFileTypeValue {
    if (dataExport.downloadType === DownloadType.excel) {
      return 'csv';
    }
    return 'pdf';
  }

  async function submitDownloadRequest() {
    key.current = cuid();

    const { settings, startDate, endDate, vendorKey, categoryKey, createByKey, paymentMethodKey } =
      dataExport;
    const filter = DownloadRequestFilterSchema.parse({
      dateStart: startDate,
      dateEnd: endDate,
      summaryUnit: SummaryUnit.Enum.year,
      summaryTemplate: 'summary',
      includeArchived: settings.includeArchived,
      includeAttachments: settings.downloadReceipts,
      reportTemplate: 'report',
      vendorKeys: vendorKey ? [vendorKey] : null,
      categoryKeys: categoryKey ? [categoryKey] : null,
      createdByKeys: createByKey ? [createByKey] : null,
      paymentMethodKeys: paymentMethodKey ? [paymentMethodKey] : null,
    });

    const exportType = getExportType();
    const fileType = getExportFileType();

    const downloadExpenseRequest = DownloadExpenseRequestSchema.parse({
      filter,
      key: key.current,
      exportType,
      exportFileType: fileType,
      ...entityFields('create', user?.uid ?? 'none'),
    });

    await addRequest(downloadExpenseRequest);
  }

  return { submitDownloadRequest, error, sharedResource };
}

export function useExportPDFData(dataExport: DataExport) {
  const user = currentUser();
  const key = React.useRef(cuid());
  const saveRequest = FilteredPDFRequestCRUD.useAddRequest();

  const [usedDoc, { error }] = useDocumentData(
    useFirestoreWorkspaceDocumentPath('filteredPDFRequest', key.current),
  );
  const [filteredPDFRequest] = useDocumentData<SharedFile>(
    usedDoc?.sharedResourceID ? `sharedResources/${usedDoc.sharedResourceID}` : null,
  );

  async function submitPDFDownloadRequest() {
    key.current = cuid();
    const { startDate, endDate, vendorKey, categoryKey, paymentMethodKey } = dataExport;

    const filter = FilteredPDFRequestFilterSchema.parse({
      dateStart: startDate,
      dateEnd: endDate ?? null,
      paymentMethods: paymentMethodKey ? [paymentMethodKey] : null,
      categories: categoryKey ? [categoryKey] : null,
      vendors: vendorKey ? [vendorKey] : null,
      reports: null,
      tripClasses: null,
      locations: null,
      //So that when any category or vendor is selected, all incomes are filterd out
      clients: vendorKey ? [vendorKey] : null,
      incomeCategories: categoryKey ? [categoryKey] : null,
      downloadAttachments: dataExport.settings.downloadReceipts,
      includeArchived: dataExport.settings.includeArchived,
    });
    await saveRequest(
      FilteredPDFRequestSchema.parse({
        filter,
        key: key.current,
        pdfTemplate: 'report',
        documentMeta: { title: 'Data Export' },
        emailOptions: null,
        ...entityFields('create', user?.uid ?? 'none'),
      }),
    );
  }
  return { submitPDFDownloadRequest, filteredPDFRequest, error };
}
